
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component({})
export default class PuNutshells extends Vue {
  @Prop({ required: true })
    nutshells!: string[]

  @Prop({ default: false })
    withJumpLink: boolean
}
