import { render, staticRenderFns } from "./CampsiteDetail.vue?vue&type=template&id=2e719a7a&scoped=true"
import script from "./CampsiteDetail.vue?vue&type=script&lang=ts"
export * from "./CampsiteDetail.vue?vue&type=script&lang=ts"
import style0 from "./CampsiteDetail.vue?vue&type=style&index=0&id=2e719a7a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e719a7a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CampsiteBreadcrumbs: require('/app/components/breadcrumbs/CampsiteBreadcrumbs.vue').default,SearchSummaryBar: require('/app/components/search/summary-bar/SearchSummaryBar.vue').default,CampsiteDetailBookableListing: require('/app/apps/campsite-details/components/CampsiteDetailBookableListing.vue').default,CampsiteDetailFreeListing: require('/app/apps/campsite-details/components/CampsiteDetailFreeListing.vue').default,CampsiteDetailFaq: require('/app/apps/campsite-details/components/CampsiteDetailFaq.vue').default,CategoryBreadcrumbs: require('/app/components/breadcrumbs/CategoryBreadcrumbs.vue').default,CampsiteDetailBackButton: require('/app/apps/campsite-details/components/CampsiteDetailBackButton.vue').default,BackToTop: require('/app/components/ui/BackToTop.vue').default})
