
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

interface SelectOption {
  name: string
  id: string
}

@Component
export default class BaseMultiCheckbox extends Vue {
  @Prop()
  options: SelectOption[]

  selected = new Set()

  updateSelected(id: string, value: boolean) {
    this.selected[value ? 'add' : 'delete'](id)
    this.$emit('change', [...this.selected])
  }
}
