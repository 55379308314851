
import { routeToFilters } from '~/apps/pu-links/infrastructure/routeToFilters'
import { HomepageLinkGenerator } from '~/apps/url/generators/HomepageLinkGenerator'
import { SearchLinkGenerator } from '~/apps/url/generators/SearchLinkGenerator'
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component
export default class BaseBreadcrumbsGenerator extends Vue {
  @Prop({ default: true, type: Boolean })
    linkAll: boolean

  @Prop({ default: true, type: Boolean })
    withCounts: boolean

  getSearchLinkGenerator() {
    return new SearchLinkGenerator()
  }

  get searchFilters() {
    return routeToFilters(this.$route)
  }

  get homeBreadcrumb() {
    const homeLinkGenerator = new HomepageLinkGenerator()
    return homeLinkGenerator.getUrlData(this.searchFilters)
  }
}
