
import { SearchBreadcrumbWithCount } from '~/components/search/types'
import { Component, Prop } from '~/utility/pu-class-decorator'
import { CampsiteBreadcrumbsGenerator } from '../../apps/pu-links/breadcrumbs/CampsiteBreadcrumbsGenerator'
import { SearchLinkGenerator } from '../../apps/url/generators/SearchLinkGenerator'
import BaseBreadcrumbsGenerator from './BaseBreadcrumbsGenerator.vue'

interface HierarchyBreadcrumb {
  name: string
  path: string
}

interface Breadcrumbs {
  name: string
  url: string
}

@Component<CampsiteBreadcrumbs>({
  head: function () {
    return this.head()
  },
})
export default class CampsiteBreadcrumbs extends BaseBreadcrumbsGenerator {
  @Prop()
    categories: string[]

  @Prop()
    hierarchyBreadcrumbs: HierarchyBreadcrumb[]

  @Prop({ default: () => [] })
    breadcrumbs: Breadcrumbs[]

  private breadcrumbsGenerator = new CampsiteBreadcrumbsGenerator()

  get categoriesLink() {
    const searchLinkGenerator = new SearchLinkGenerator({ noCategories: true })
    return searchLinkGenerator.getUrlData({
      category: 'campsites',
      filters: { ...this.searchFilters, hierarchyPath: undefined },
    })
  }

  get generatedBreadcrumbs(): SearchBreadcrumbWithCount[] {
    return this.breadcrumbsGenerator.get({
      hierarchies: this.hierarchyBreadcrumbs,
      searchFilters: this.searchFilters,
      noCategories: true,
    })
  }

  head() {
    return {
      script: [
        {
          hid: 'ldjson-schema-campsite-detail-breadcrumblist',
          type: 'application/ld+json',
          body: true,
          json: {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.breadcrumbs.map((breadcrumb, index) => {
              return {
                '@type': 'ListItem',
                position: index + 1,
                item: {
                  '@id': `https://${this.$config.public.envUrl}${breadcrumb.url}`,
                  name: breadcrumb.name,
                },
              }
            }),
          },
        },
      ],
    }
  }
}
