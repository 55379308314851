
import { Component, Prop } from '~/utility/pu-class-decorator'
import { getCampsiteCategoriesPresenter } from '~/apps/categories/presenter-json/CampsiteCategoriesUpdaterJson'
import { CampsiteCategory } from '~/utility/types/CampsiteCategory'
import { UrlQuery } from '../../apps/url/domain/UrlQuery'
import BaseBreadcrumbsGenerator from './BaseBreadcrumbsGenerator.vue'

interface HierarchyPath {
  name: string
  path: string
}

interface CategoryBreadcrumb {
  categoryName: string
  hierarchyName: string
  to: {
    path: string
    query?: UrlQuery
  }
}

@Component
export default class CategoryBreadcrumbs extends BaseBreadcrumbsGenerator {
  @Prop()
    hierarchies: HierarchyPath[]

  @Prop({ default: () => [] })
    campsiteCategories: string[]

  get hasGlampingCategory() {
    return (
      this.hierarchy &&
      this.hierarchy.name &&
      this.campsiteCategories.length &&
      (this.campsiteCategories.includes('7') ||
        this.campsiteCategories.includes('13'))
    )
  }

  get categoryBreadcrumbsList(): CategoryBreadcrumb[] {
    const categories = getCampsiteCategoriesPresenter(
      this.$route.params.lang || 'en-gb',
    )
    const breadcrumbs: CategoryBreadcrumb[] = []
    this.campsiteCategories.forEach((categoryId) => {
      const category = categories.byIdMap[categoryId]
      if (category && category.id.toString() != '13' && this.hierarchy) {
        breadcrumbs.push(this.getBreadcrumbObject(category, this.hierarchy))
      }
    })
    return breadcrumbs
  }

  get hierarchy() {
    if (this.hierarchies.length === 0) {
      return null
    }
    const hierarchies = this.hierarchies
    if (hierarchies.length === 2) {
      return hierarchies[1]
    }
    return hierarchies[hierarchies.length - 2]
  }

  getBreadcrumbObject(
    category: CampsiteCategory,
    hierarchy: HierarchyPath,
  ): CategoryBreadcrumb {
    return {
      categoryName: category.breadcrumbName,
      hierarchyName: hierarchy.name,
      to: this.getSearchLinkGenerator().getUrlData({
        category: category.rootSlug,
        filters: this.searchFilters,
        hierarchyPath: hierarchy.path,
      }),
    }
  }
}
