
import { PhotoLoaderService } from '~/shared/photos/photoLoader'
import { PitchupPhoto } from '~/shared/photos/types'
import isNotAnonAxiosError from '~/utility/isNotAnonAxiosError'
import { Component, Prop, Vue, Watch } from '~/utility/pu-class-decorator'

@Component
export default class PuCampsitePhotosProvider extends Vue {
  @Prop()
    primaryPhoto: PitchupPhoto

  @Prop()
    campsiteId: string

  @Prop()
    campsiteName: string

  @Prop()
    categoryIds: string[]

  @Prop({ default: false })
    isSearchResults: boolean

  @Prop({ default: false })
    galleryFullScreen: boolean

  @Prop({ required: false })
    searchResultIndex: number

  @Prop({ required: false })
    showSearchGallery: boolean

  @Prop({ required: false })
    photoCount: number

  @Prop({ required: false })
    galleryInitialIndex: number

  @Prop({ default: true })
    loadHighResOnInteraction: boolean

  // if you pass in the photos it won't fetch them
  @Prop({ default: () => [] })
    photosProp: PitchupPhoto[]

  @Prop({ default: false })
    loadGallery: boolean

  campsitePhotos: PitchupPhoto[] = []

  isVisible = false
  preInteractionPhotoCount = 8
  userHasInteracted = false

  @Watch('loadGallery', { immediate: true })
  loadGalleryChange(loadGallery) {
    if (loadGallery) {
      this.userInteractionHandler()
    }
  }

  @Watch('photosProp')
  photosPropChange(newPhotosProp) {
    if (!newPhotosProp.length && (!this.photos || !this.photos.length)) {
      void this.loadPhotos()
    }
  }

  async loadPhotos() {
    const photoLoaderService = new PhotoLoaderService()
    try {
      this.campsitePhotos = await photoLoaderService.loadPhotos(
        this.campsiteId,
        this.$i18n.locale,
      )
    } catch (e) {
      const err = e as Error
      if (isNotAnonAxiosError(err)) {
        console.error(err, JSON.stringify(err))
      }
    }
  }

  get photos() {
    let photos =
      this.photosProp && this.photosProp.length > 0
        ? this.photosProp
        : this.campsitePhotos || []

    return this.userHasInteracted
      ? photos
      : photos.slice(0, this.preInteractionPhotoCount)
  }

  get photoCountToDisplay() {
    if (this.photoCount !== undefined) {
      return this.photoCount
    } else if (!this.userHasInteracted) {
      return this.photosProp.length || this.campsitePhotos.length
    } else {
      return undefined
    }
  }

  visibilityChanged(isVisible: boolean) {
    this.isVisible = isVisible
    // $isDesktop is true for a tablet but we don't have a mouse
    // to load photos onmouseover so if we have ontouchstart event
    // load photos when visible.
    if (
      (!this.$isDesktop || 'ontouchstart' in window) &&
      isVisible &&
      (!this.photos || !this.photos.length)
    ) {
      requestIdleCallback(() => {
        void this.loadPhotos()
      })
    }
  }

  userInteractionHandler() {
    if (!this.photos || !this.photos.length) {
      this.loadPhotos()
        .then(() => {
          this.userHasInteracted = true
        })
        .catch((e) => console.error(e))
    } else {
      this.userHasInteracted = true
    }
  }
}
