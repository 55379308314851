
import { Component, Prop, Watch } from '~/utility/pu-class-decorator'
import BaseInput from './BaseInput.vue'

export interface SelectOption {
  name: string
  value: string
  selected?: boolean
  disabled?: boolean
}

@Component
export default class BaseSelect extends BaseInput {
  @Prop()
    selectOptions!: SelectOption[]

  @Prop()
    defaultSelectName!: string

  @Prop({ required: false })
    cssClass?: string

  // TODO: selected should be a prop really as can only have one
  selected: string | null = null

  @Watch('selected')
  selectedChanged(_, oldSelected) {
    // only emit if changed
    if (oldSelected !== null)
      this.$emit('change', {
        select: this.name,
        selectedValue: this.selected,
      })
  }

  @Watch('selectOptions', { immediate: true })
  changeSelection() {
    const selected = this.selectOptions.find((o) => o.selected)
    this.selected = selected ? selected.value : ''
  }

  get listeners() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { change, ...listeners } = this.$listeners
    return listeners
  }
}
