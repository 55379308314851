
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { SearchBreadcrumbWithCount } from '~/components/search/types'

@Component
export default class BreadcrumbsGenerator extends Vue {
  @Prop({ required: true })
    breadcrumbs: SearchBreadcrumbWithCount[]

  @Prop({ default: true, type: Boolean })
    linkAll: boolean

  @Prop({ default: true, type: Boolean })
    withCounts: boolean

  isCityLevel(hierarchyPath) {
    if (!hierarchyPath) {
      return false
    }
    // this will probably fail for breadcrumbs like scotland
    const level = hierarchyPath.split('/').filter((part) => part !== '').length
    return level === 4
  }
}
