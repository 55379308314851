
import { usePinia, useSeenCampsitesStore } from '#imports'
import { isEqual } from 'lodash'
import LazyHydrate from 'vue-lazy-hydration'
import { MetaInfo } from 'vue-meta'
import CampsiteDetailBookableListing from '~/apps/campsite-details/components/CampsiteDetailBookableListing.vue'
import { CampsiteDetailBookableListingProps } from '~/apps/campsite-details/components/CampsiteDetailBookableListingProps'
import CampsiteDetailFreeListing from '~/apps/campsite-details/components/CampsiteDetailFreeListing.vue'
import { getChargeTypesStateMachine } from '~/apps/campsite-details/inversify.config'
import type {
  ChargeTypesContext,
  ChargeTypesMachineInterpreter,
} from '~/apps/campsite-details/machines'
import {
  CampsiteDetails,
  ChargeTypesInput,
  PartyChangedEvent,
  PartyTransformedEvent,
  RangeChangedEvent,
} from '~/apps/campsite-details/types'
import { Review } from '~/apps/campsites/reviews/types'
import { Route } from '~/apps/pu-links/domain/Route'
import { isSearchUrlRoute } from '~/apps/search/isSearchUrlComponent'
import { SearchFiltersUrl } from '~/apps/url/search-filters/SearchFiltersUrl'
import { ensureArray } from '~/utility/array'
import { childAgeCSVStringToNumberArray } from '~/utility/child-ages/child-ages'
import { DateRange } from '~/utility/date/DateRange'
import { isValidDate } from '~/utility/date/isValidDate'
import { addDays } from '~/utility/date/relative'
import { getPointFromStringOrDefault } from '~/utility/geo/latLng'
import { Component, Watch } from '~/utility/pu-class-decorator'
import { waitForInterpreterState } from '~/utility/xstate/waitForInterpreterState'
import { ReviewsRepositoryFetch } from '../../apps/campsites/reviews/ReviewsRepositoryFetch'
import { useRecentCampsitesStore } from '../../stores/useRecentCampsitesStore'
import { getYearForTitleDate } from '../../utility/next-year'
import { sanitize } from '../../utility/sanitize'
import { SearchSummaryBarProps } from '../search/summary-bar/SearchSummaryBarProps'
import {
  CampsiteFetchingPage,
  campsiteFetchingPageAsyncData,
} from './CampsiteFetchingPage'

interface RouterQueryParams
  extends Partial<PartyTransformedEvent>,
  Partial<RangeChangedEvent> {}

const isDev = process.env.NODE_ENV === 'development'

function flatten<T>(arr: T[][]): T[] {
  return ([] as T[]).concat(...arr)
}

function queryWrapper(query: Route['query']) {
  const ret: Record<string, string> = {}
  for (const key in query) {
    const val = ensureArray(query[key])
    if (val.length === 1 && val[0] !== null) {
      ret[key] = val[0]
    }
  }
  return ret
}

async function getChargeTypesState(
  campsite: CampsiteDetails,
  params: ChargeTypesInput,
  langCode: string,
) {
  const chargeTypesStateMachine = getChargeTypesStateMachine(langCode).start()
  chargeTypesStateMachine.send({
    type: 'FETCH_CHARGETYPES',
    pitchtypes: campsite.pitchtypes,
    params,
  })
  await waitForInterpreterState({
    machine: chargeTypesStateMachine,
    successStates: ['success'],
    errorStates: ['error'],
  }).catch((error) => {
    console.error('Error fetching charge types', error)
  })
  chargeTypesStateMachine.stop()
  const chargeTypesStateStrings = chargeTypesStateMachine.state.toStrings()
  const chargeTypesContext = chargeTypesStateMachine.state.context
  return { chargeTypesStateStrings, chargeTypesContext }
}

@Component<CampsiteDetail>({
  scrollToTop: true,
  setup() {
    const seenCampsitesStore = useSeenCampsitesStore(usePinia())
    const recentCampsitesStore = useRecentCampsitesStore(usePinia())
    return { seenCampsitesStore, recentCampsitesStore }
  },
  watchQuery: false,
  asyncData: async (ctx) => {
    const result = await campsiteFetchingPageAsyncData(ctx)
    if (
      process.server &&
      result &&
      'campsite' in result &&
      result.campsite &&
      result.campsite.primaryPhoto
    ) {
      ctx.res.setHeader(
        'Link',
        `</_cfi/cdn-cgi/image/format=auto,fit=cover,quality=80,w=360,h=270${result.campsite.primaryPhoto?.url.masterImage}>; rel="preload"; as="image"; imagesrcset="/_cfi/cdn-cgi/image/format=auto,fit=cover,quality=80,w=360,h=270${result.campsite.primaryPhoto?.url.masterImage}  360w, /_cfi/cdn-cgi/image/format=auto,fit=cover,quality=30,w=720,h=540${result.campsite.primaryPhoto?.url.masterImage} 720w, /_cfi/cdn-cgi/image/format=auto,fit=cover,quality=30,w=1080,h=810${result.campsite.primaryPhoto?.url.masterImage} 1080w"; imagesizes="100vw"`,
      )
    }
    // is dated
    if (
      ctx.route.query.arrive &&
      isValidDate(ctx.route.query.arrive) &&
      result &&
      'campsite' in result &&
      result.campsite
    ) {
      const campsite = result.campsite
      const query = queryWrapper(ctx.route.query)
      const children = query.child_ages ? query.child_ages.split(',').length : 0
      const params: ChargeTypesInput = {
        campsiteId: campsite.id,
        arrive: query.arrive,
        depart: query.depart ? query.depart : null,
        adults: query.adults ? Number(query.adults) : null,
        children,
        childAges: query.child_ages ? query.child_ages : null,
        nights: query.nights ? Number(query.nights) : null,
        availability: true,
      }
      return {
        ...result,
        ...(await getChargeTypesState(
          campsite,
          params,
          ctx.route.params.lang || 'en-gb',
        )),
        lastFetchParams: SearchFiltersUrl.urlToFilters({ ...ctx.route.query }),
      }
    }
    return result
  },
  httpHeaders: (ctx) => {
    const cacheTime =
      'arrive' in ctx.route.query
        ? ctx.$config.priceCacheTime
        : ctx.$config.nonPriceCacheTime
    const graceTime =
      'arrive' in ctx.route.query
        ? ctx.$config.priceGraceTime
        : ctx.$config.nonPriceGraceTime
    const currentXkey = ctx.res.getHeader('xkey')
    let newXkey = sanitize(ctx.route.params.slug)
    if (currentXkey !== undefined) {
      newXkey = `${currentXkey.toString()} ${newXkey}`
    }
    return {
      'Cache-Control': `max-age=${cacheTime}, public`,
      Grace: `${graceTime}`,
      xkey: newXkey,
    }
  },
  middleware: ['httpHeaders'],
  head: function () {
    return this.head()
  },
  fetch: function () {
    return this.fetch()
  },
  components: {
    CampsiteDetailBookableListing,
    CampsiteDetailFreeListing,
    LazyHydrate,
  },
})
export default class CampsiteDetail extends CampsiteFetchingPage {
  seenCampsitesStore: ReturnType<typeof useSeenCampsitesStore>
  recentCampsitesStore: ReturnType<typeof useRecentCampsitesStore>

  chargeTypesStateStrings: string[] | null = null
  chargeTypesContext: ChargeTypesContext | null = null
  // controls if component has been activated in a keep-alive
  isActive = true
  // the current review if there is one
  review: Review | null = null

  async fetch() {
    if (this.reviewId) await this.setUpReview(this.reviewId)
    return Promise.resolve()
  }

  activated() {
    if (isDev)
      console.log(
        'CampsiteDetail.activated',
        this.campsiteSlug,
        this.$route.fullPath,
      )
    this.isActive = true
    this.start()
  }

  deactivated() {
    if (isDev)
      console.log(
        'CampsiteDetail.deactivated',
        this.campsiteSlug,
        this.$route.fullPath,
      )
    this.isActive = false
    this.lastFetchParams = null
  }

  head(): MetaInfo {
    const campsiteName = this.campsite.name
    const town = this.campsite.hierarchyTextShort
    const point = getPointFromStringOrDefault(this.campsite.point)
    let title = ''
    if (this.campsite.isBookable) {
      title =
        (this.$i18n.t(
          '{campsiteName}, {town} - Updated {year, puDate, year:numeric} prices',
          { campsiteName, town, year: getYearForTitleDate() },
        ) as string) + ' | Pitchup.com'
    } else {
      title =
        (this.$i18n.t('{campsiteName}, {town}', {
          campsiteName,
          town,
        }) as string) + ' | Pitchup.com'
    }
    let primaryPhoto = ''
    if (this.campsite.primaryPhoto?.url.masterImage) {
      primaryPhoto = this.campsite.primaryPhoto?.url.masterImage
    } else {
      primaryPhoto = `https://${this.$config.public.envUrl}/app-icons/pitchup_logo_og.png`
    }
    let aggregateRating = {}
    if (this.campsite.rateCount && this.campsite.rating) {
      aggregateRating = {
        '@type': 'AggregateRating',
        ratingValue: parseFloat((this.campsite.rating * 2).toFixed(1)),
        reviewCount: this.campsite.rateCount,
        worstRating: '1',
        bestRating: '10',
      }
    }
    return {
      title,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.review ? this.getReviewOgTitle() : title,
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.getOgUrl(),
        },
        {
          hid: 'description',
          name: 'description',
          property: 'og:description',
          content: this.getOgDescription(),
          'data-brainlabs-price':
            this.campsite.isBookable && this.campsite.leadPrice
              ? `${this.campsite.leadPrice.currency} ${this.campsite.leadPrice.amount}`
              : '',
        },
        ...flatten(
          this.campsite.seoPhotos?.slice(0, 5).map((photo, index) => {
            const photos = [
              {
                hid: `og:image${index > 0 ? index : ''}`,
                property: 'og:image',
                content: `https://${this.$config.public.envUrl}${photo.url}`,
              },
              {
                hid: `og:image${index > 0 ? index : ''}:width`,
                property: 'og:image:width',
                content: `${photo.width}`,
              },
              {
                hid: `og:image${index > 0 ? index : ''}:height`,
                property: 'og:image:height',
                content: `${photo.height}`,
              },
            ]
            if (photo.caption) {
              photos.push({
                hid: `og:image${index > 0 ? index : ''}:alt`,
                property: 'og:image:alt',
                content: photo.caption,
              })
            }
            return photos
          }) || [],
        ),
      ],
      htmlAttrs: {
        prefix:
          'og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# pitchup: http://ogp.me/ns/fb/pitchup#',
      },
      script: [
        {
          hid: 'ldjson-schema-campsite-detail',
          type: 'application/ld+json',
          body: true,
          json: {
            '@context': 'https://schema.org',
            '@type': this.campsite.path.includes('Wales/Mid-Wales/Ceredigion')
              ? 'Campground'
              : 'LodgingBusiness',
            name: campsiteName,
            description: this.campsite.description,
            url: `https://${this.$config.public.envUrl}${this.$route.fullPath}`,
            address: {
              '@type': 'PostalAddress',
              streetAddress: this.streetAddress,
              addressLocality:
                (this.campsite.hierarchy && this.campsite.hierarchy.town) || '',
              addressRegion: this.campsite.countyOrRegion || '',
              postalCode: this.campsite.postcode,
              addressCountry:
                (this.campsite.hierarchy && this.campsite.hierarchy.country) ||
                '',
            },
            aggregateRating,
            image: primaryPhoto,
            availableLanguage: this.campsite.languages.map((language) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const obj: any = {
                '@type': 'Language',
                name: language.name,
              }
              if (language.code) {
                obj.alternateName = language.code
              }
              return obj
            }),
            priceRange: this.campsite.seoPriceRange,
            geo: {
              '@type': 'GeoCoordinates',
              latitude: point.lat,
              longitude: point.lng,
            },
            potentialAction: this.campsite.isBookable
              ? this.potentialAction
              : '',
          },
        },
        ...(this.campsite.seoPhotos?.map((photo, index) => ({
          hid: 'ldjson-schema-campsite-detail-photo-' + index,
          type: 'application/ld+json',
          body: true,
          json: {
            '@context': 'https://schema.org',
            '@type': 'ImageObject',
            name: this.campsite.name,
            contentLocation: `${this.campsite.name}, ${this.campsite.hierarchyTextShort}`,
            contentUrl: `https://${this.$config.public.envUrl}${photo.url}`,
            description: photo.caption,
          },
        })) || []),
      ],
    }
  }

  start() {
    if (isDev)
      console.log(
        'CampsiteDetail.start',
        this.campsiteSlug,
        this.$route.fullPath,
      )
    if (this.isDated) {
      this.startChargeTypesStateMachine()
    }
    const prefix = this.$route.params.lang ? `/${this.$route.params.lang}` : ''
    const metaData = new URLSearchParams({
      isDesktop: this.$isDesktop.toString(),
      alfred: 'true',
      alfredVersion: this.$config.public.alfredVersion,
      isDated: ('arrive' in this.$route.query).toString(),
      path: this.$route.fullPath,
    })
    requestIdleCallback(() => {
      $fetch(
        `/_/fallback${prefix}/pagestat/campsite/${this.campsite.id}/page_view/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: metaData,
        },
      ).catch(() => {
        // console.error('Error sending POST request:', error)
      })
    })

    requestIdleCallback(async () => {
      await this.addCampsiteToRecent()
    })
    this.seenCampsitesStore.add(this.campsite.slug)
    this.$gtm.push({
      pageCategories: this.campsite.categories
        .map((category) => category.slug)
        .join(','),
    })
    if (this.$route.hash.startsWith('#reviews')) {
      setTimeout(() => {
        this.$puScrollTo('[data-reviews-scroll-target]', { duration: 300 })
      }, 500)
    }
  }

  private async setUpReview(reviewId: string) {
    const repo = new ReviewsRepositoryFetch()
    try {
      this.review = await repo.readOneById(this.$route.params.lang || 'en-gb', {
        campsiteSlug: this.campsiteSlug,
        id: reviewId,
      })
    } catch (error) {
      console.error(error)
    }
  }

  private getReviewOgTitle(): string {
    return this.$t("{username}'s review of {campsite} on Pitchup.com", {
      username: this.review?.user?.username,
      campsite: this.campsite.name,
    }) as string
  }

  private getOgDescription() {
    return (
      this.review
        ? (this.getReviewOgDescription() as string)
        : this.campsite.og_description ?? this.campsite.description ?? ''
    ).slice(0, 300)
  }

  private getReviewOgDescription() {
    return `${this.review?.title} ${this.review?.liked}`
  }

  private getOgUrl(): string {
    return this.review
      ? `${this.campsite.og_url}?review=${this.review.id}`
      : (this.campsite.og_url as string)
  }

  private async addCampsiteToRecent() {
    if (isDev) console.log('addCampsiteToRecent', this.campsite.id)
    await this.recentCampsitesStore.addCampsite(this.campsite.id)
  }

  rangeChanged(dates: RangeChangedEvent) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cloneDates: any = { ...dates }
    if (this.dateRange.start === dates.arrive) delete cloneDates.arrive
    if (this.dateRange.end === dates.depart) delete cloneDates.depart
    if (Object.keys(cloneDates).length) this.updateRouteQueryParams(cloneDates)
  }

  // note we do this twice, there is asyncData one and this one
  private startChargeTypesStateMachine() {
    const routeParams = SearchFiltersUrl.urlToFilters({ ...this.$route.query })
    if (isEqual(routeParams, this.lastFetchParams)) {
      // Parameters are the same, no need to fetch again
      return
    }
    this.lastFetchParams = routeParams
    const chargeTypesStateMachine = getChargeTypesStateMachine(
      this.$route.params.lang || 'en-gb',
    ).onTransition((state) => {
      this.chargeTypesStateStrings = state.toStrings()
      this.chargeTypesContext = state.context
      // scroll to pitchtype if we have results
      if (state.matches('success') && state.context.hasAvailability) {
        requestAnimationFrame(() => {
          this.$puScrollTo('[data-pitchtypes-scroll-target]', { duration: 300 })
        })
      }
    })
    chargeTypesStateMachine?.start()
    this.fetchChargeTypes(chargeTypesStateMachine)
  }

  private lastFetchParams: ChargeTypesInput | null = null

  private fetchChargeTypes(
    chargeTypesStateMachine: ChargeTypesMachineInterpreter,
  ) {
    const arrive = this.dateRange.start
    const depart = this.dateRange.end
      ? this.dateRange.end
      : addDays(this.minDays, this.dateRange.start!)
    const nights = new DateRange(arrive, depart).nights
    const params: ChargeTypesInput = {
      campsiteId: this.campsite.id,
      arrive,
      depart,
      adults: this.party.adults,
      children: this.party.childAges.length,
      childAges: this.party.childAges.join(','),
      nights,
      availability: true,
    }
    chargeTypesStateMachine.send({
      type: 'FETCH_CHARGETYPES',
      pitchtypes: this.campsite.pitchtypes,
      params,
    })
  }

  partyChanged(party: PartyChangedEvent) {
    const params: PartyTransformedEvent = { adults: party.adults }
    if (party.children && party.children > 0) {
      params.children = String(party.children)
      params.child_ages = party.childAges?.length
        ? party.childAges?.join(',')
        : ''
    } else {
      params.children = undefined
      params.child_ages = undefined
    }
    this.updateRouteQueryParams(params)
  }

  ptsViewed = 0
  ptsViewedChanged(number: number) {
    this.ptsViewed = number
  }

  clearDates() {
    if (this.$route.query.arrive || this.$route.query.depart) {
      const query = {
        ...this.$route.query,
        arrive: undefined,
        depart: undefined,
        max_price: undefined,
        min_price: undefined,
      }
      this.$router.push({ query }, undefined, undefined)
    }
  }

  private updateRouteQueryParams(params: RouterQueryParams) {
    this.$router.push(
      {
        query: this.removeEmptyQueryParams({ ...this.$route.query, ...params }),
      },
      undefined,
      undefined,
    )
  }

  private removeEmptyQueryParams(params) {
    return Object.entries(params).reduce((query, entry) => {
      if (entry[1]) {
        query[entry[0]] = entry[1]
      }
      return query
    }, {})
  }

  get bookableListingProps(): CampsiteDetailBookableListingProps {
    const pitchtypeFacets = this.campsite.pitchtypeFacets || []
    const facets = this.$route.query.facet
      ? ensureArray(this.$route.query.facet).filter(
        (f) => !!f && pitchtypeFacets.includes(f),
      )
      : []
    return {
      campsite: this.campsite,
      categoryIds: this.categoryIds,
      isDated: this.isDated,
      hasAvailability: this.hasAvailability,
      party: this.party,
      chargetypes: this.chargetypes,
      types: this.types,
      dateRange: this.dateRange,
      pitchtypeId: this.pitchtypeId,
      scrollToPitchtypeId: this.$route.query.scroll_to_pitchtype as string,
      searchFilters: this.searchFilters,
      previousPageWasCheckAvailability: this.previousPageWasCheckAvailability,
      reviewId: this.reviewId,
      chargeTypesUpdating: this.chargeTypesUpdating,
      breadcrumbs: this.campsite.breadcrumbs,
      facets: facets as string[],
    }
  }

  private get chargeTypesUpdating() {
    if (!this.$route.query.arrive) {
      return false
    }
    return this.chargeTypesStateStrings
      ? this.chargeTypesStateStrings.includes('fetchingChargeTypes')
      : true
  }

  private get minDays(): number {
    return (
      this.chargeTypesContext?.chargetypes.find(
        ({ pitchtypeId }) => this.pitchtypeId === pitchtypeId,
      )?.minDays || 1
    )
  }

  get streetAddress() {
    const address2 = this.campsite.address2 ? this.campsite.address2 : ''
    if (address2) {
      return `${this.campsite.address1}, ${address2}`
    }
    return this.campsite.address1
  }

  get categoryIds() {
    return (this.searchFilters.categoryIds || []).filter((catId) =>
      this.campsite.categories.map((category) => category.id).includes(catId),
    )
  }

  get hasAvailability(): boolean | null {
    const availability = this.chargeTypesContext?.hasAvailability
    const availabilitySet = availability !== undefined
    return availabilitySet ? !!availability : null
  }

  get chargetypes() {
    return this.chargeTypesContext?.chargetypes || []
  }

  get dateRange() {
    return {
      start: this.searchFilters.dates?.arrive || '',
      end: this.searchFilters.dates?.depart || '',
    }
  }

  get dateRangeNights() {
    const { start, end } = this.dateRange
    return start && end ? new DateRange(start, end).nights : 1
  }

  get childAges() {
    return this.$route.query.child_ages
      ? childAgeCSVStringToNumberArray(this.$route.query.child_ages as string)
      : []
  }

  get children() {
    return this.childAges.length
  }

  get party() {
    return {
      adults: this.adults,
      childAges: this.childAges,
    }
  }

  get isDated() {
    return !!this.$route.query?.arrive
  }

  get types() {
    return ensureArray(this.$route.query.type).map((type) =>
      parseInt(type as string, 10),
    )
  }

  get adults() {
    const party = this.searchFilters.party
    return party ? party.adults : 2
  }

  get searchFilters() {
    return SearchFiltersUrl.urlToFilters({ ...this.$route.query })
  }

  @Watch('searchFilters')
  searchFiltersUpdated(newVal, oldVal) {
    if (this.isDated && !isEqual(newVal, oldVal) && this.isActive) {
      if (isDev)
        console.log(
          'CampsiteDetail.searchFiltersUpdated',
          this.campsiteSlug,
          this.$route.fullPath,
        )
      this.startChargeTypesStateMachine()
    }
  }

  get searchSummaryBarProps(): SearchSummaryBarProps {
    return {
      searchFilters: this.searchFilters,
      searchReferrerRoute: this.searchReferrerRoute,
      campsiteSlug: this.campsite.slug,
      ptsViewed: this.ptsViewed,
    }
  }

  get searchReferrerRoute(): Route | null {
    const referrer = this.$routerClient.getReferrer()
    if (referrer && isSearchUrlRoute(referrer)) {
      return referrer
    }
    return null
  }

  get previousPageWasCheckAvailability() {
    return (
      this.$routerClient.getReferrer()?.name ===
      'campsite-slug-check-availability'
    )
  }

  get campsiteCategories() {
    return this.campsite.categories.map((c) => c.id.toString())
  }

  get reviewId() {
    const reviewFromQuery = ensureArray(
      this.$route.query.review || this.$route.query.review_posted,
    )[0]
    return reviewFromQuery || undefined
  }

  get potentialAction() {
    const json = {
      '@type': 'ReserveAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `https://${this.$config.public.envUrl}${this.$route.fullPath}`,
        inLanguage: this.campsite.locale,
        actionPlatform: [
          'http://schema.org/MobileWebPlatform',
          'http://schema.org/DesktopWebPlatform',
        ],
      },
      result: {
        '@type': 'LodgingReservation',
        name: this.$i18n.t('Booking at {campsiteName}', {
          campsiteName: this.campsite.name,
        }) as string,
      },
    }
    return json
  }
}
