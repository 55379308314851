
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { AllowedSubcategory } from '../../apps/campsite-details/types'

@Component
export default class UnitPerPitch extends Vue {
  @Prop({ required: true })
    oneUnitPerPitch: boolean

  @Prop({ required: true })
    allowedSubcategories: AllowedSubcategory[]

  get categoriesText() {
    return this.allowedSubcategories.join(` ${this.$t('or')} `)
  }
}
