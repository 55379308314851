import { Context } from '@nuxt/types'
import { CampsiteDetails } from '~/apps/campsite-details/types'
import { Component, Vue } from '~/utility/pu-class-decorator'
import { getMetaFromRoute } from '~/utility/route'
import { AjaxCampsiteDetailsRepository } from '../../apps/campsite-details/AjaxCampsiteDetailsRepository'
import { logger } from '../../utility/logger'

@Component
export class CampsiteFetchingPage extends Vue {
  campsite: CampsiteDetails
  loading: boolean = true

  validate({ params }) {
    // check we have a slug
    return params.slug && isNaN(params.slug)
  }

  get campsiteSlug() {
    return this.$route.params.slug
  }

  get pitchtypeId(): string | (string | null) {
    return Array.isArray(this.$route.query.pitchtype)
      ? this.$route.query.pitchtype[0]
      : this.$route.query.pitchtype
  }
}

export async function campsiteFetchingPageAsyncData(context: Context) {
  logger('campsiteFetchingPageAsyncData', context.route.fullPath)
  const { route, error, app, redirect } = context
  const $i18n = app.i18n
  const repository = new AjaxCampsiteDetailsRepository()
  const slug = route.params.slug
  const meta = getMetaFromRoute(route)
  try {
    const campsite = await repository.get(
      slug,
      route.params.lang || 'en-gb',
      route.query,
    )
    if (campsite) {
      // check campsite path and slug
      const oldUrl = route.fullPath.toString()
      const newUrl = oldUrl.replace(
        new RegExp(campsite.path, 'ig'),
        campsite.path,
      )
      if (newUrl !== oldUrl) {
        return redirect(301, newUrl)
      }
      // check if valid hierarchy
      if (meta.doCheckRoute) {
        let redirectUrl = `/${
          $i18n.locale !== 'en-gb' ? $i18n.locale + '/' : ''
        }campsites/${campsite.path}${campsite.slug}/`
        if (route.path !== redirectUrl) {
          const queryParams = new URLSearchParams(route.query)
          if (queryParams.toString()) {
            redirectUrl += `?${queryParams.toString()}`
          }
          return redirect(301, redirectUrl)
        }
      }
      return { campsite, loading: false }
    }
    return error({ statusCode: 404 })
  } catch (_err) {
    if (
      (_err as { response?: { statusCode: number } })?.response?.statusCode ===
        404 ||
      (_err as { response?: { status: number } })?.response?.status === 404
    ) {
      return error({ statusCode: 404 })
    }
    return error({ statusCode: 500, message: _err as string })
  }
}
