
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component
export default class ExtraDescription extends Vue {
  @Prop()
    extraDescription: string

  @Prop()
    extraTerms: string[]

  get popoverContent() {
    let content = ''
    if (this.extraDescription) {
      content += '<span>' + this.extraDescription + '</span>'
    }
    if (this.extraTerms && this.extraTerms.length > 0) {
      for (const term of this.extraTerms) {
        content += term + '<br>'
      }
    }
    return content
  }
}
