import { render, staticRenderFns } from "./GuestsForm.vue?vue&type=template&id=16d43002&scoped=true"
import script from "./GuestsForm.vue?vue&type=script&lang=ts"
export * from "./GuestsForm.vue?vue&type=script&lang=ts"
import style0 from "./GuestsForm.vue?vue&type=style&index=0&id=16d43002&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d43002",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Translate: require('/app/components/Translate.vue').default,NumberStepper: require('/app/components/ui/NumberStepper.vue').default,GroupBooking: require('/app/apps/search-wizard/components/GroupBooking.vue').default})
