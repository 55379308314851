
import { WEEK, olderThan } from '~/utility/date/relative'
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component
export default class LastBooked extends Vue {
  @Prop({ required: true })
    date: string

  get withinLastWeek() {
    return !olderThan(this.date, WEEK)
  }
}
