
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { CampsiteNotices } from '../../apps/campsite-details/types'

@Component
export default class CampsiteNoticesComponent extends Vue {
  @Prop({ default: () => [] })
    warnings: string[]

  @Prop({ required: true })
    notices: string

  @Prop({ default: false })
    noticesNeedsTranslation: boolean

  @Prop()
    campsiteNotices: CampsiteNotices[]

  get filteredCampsiteNotices() {
    return this.campsiteNotices?.filter(
      (notice) => !notice.isCovid && !this.warnings.includes(notice.text),
    )
  }
}
