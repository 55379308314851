
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import type {
  GuestsFormErrors,
  GuestsFormParty,
} from '~/components/forms/guests-form/schemas'

@Component<GuestsForm>({
  head() {
    return {
      bodyAttrs: {
        class: this.$isDesktop ? '' : 'noscroll',
      },
    }
  },
})
export default class GuestsForm extends Vue {
  @Prop({ required: true })
    guests: GuestsFormParty

  @Prop({ required: true })
    pitchtypeCapacity: number

  validationErrors: GuestsFormErrors = {
    emptyParty: false,
    childAges: false,
  }

  adults: number = 2
  children: number = 0
  childAges: number[] = []

  minAdultAge = [...Array(19).keys()]

  beforeMount() {
    this.adults = this.guests.adults
    this.children = this.guests.childAges.length
    this.childAges = [...this.guests.childAges]
  }

  isChildAgesValid(): boolean {
    return (
      this.childAges.slice(0, this.children).filter((a) => a >= 0).length ===
      this.children
    )
  }

  isPartyWithPeople(): boolean {
    return this.adults !== 0 || this.children !== 0
  }

  doesChildAgeExist(childIndex: number) {
    return this.childAges[childIndex - 1] >= 0
  }

  validateGuestsModel() {
    this.clearValidationErrors()
    this.validationErrors.emptyParty = !this.isPartyWithPeople()
    if (this.childAges) {
      this.validationErrors.childAges = !this.isChildAgesValid()
    }
  }

  clearValidationErrors() {
    this.validationErrors = {
      emptyParty: false,
      childAges: false,
    }
  }

  changeGuests() {
    if (!this.isValid) this.validateGuestsModel()
  }

  closeForm() {
    this.clearValidationErrors()
    this.$emit('form-closed')
  }

  emitPartyChange() {
    this.validateGuestsModel()
    if (this.isValid) {
      this.$emit('party-changed', this.sanitizedGuests)
    }
  }

  get isValid() {
    return !this.validationErrors.emptyParty && !this.validationErrors.childAges
  }

  get sanitizedGuests(): GuestsFormParty {
    const childAges =
      this.childAges.length > this.children
        ? this.childAges.slice(0, this.children)
        : this.childAges
    return {
      adults: this.adults,
      childAges,
      children: this.children,
    }
  }
}
