// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/bullet.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "ul[data-v-07099d24]{list-style:none;margin:1em 0 .8em;padding:0}li[data-v-07099d24]{color:var(--colour-text-default);font-size:var(--body);line-height:1.2;list-style-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");padding:0 0 .5em;text-align:left}li a[data-v-07099d24]{color:var(--colour-text-light);-webkit-text-decoration:underline;text-decoration:underline}.single-nutshell li[data-v-07099d24]{font-size:var(--small);list-style:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
