
import { daysBetween } from '~/utility/date/relative'
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component
export default class NewCampsiteBadge extends Vue {
  @Prop()
    firstAvailableDate: string

  get isCampsiteNew() {
    return (
      daysBetween(this.firstAvailableDate) <=
      Number(process.env.defaultMaximumNewCampsiteDays)
    )
  }
}
